<template>
  <div>
    <b-list-group class="mt-3 p-0 pb-4 border-0 commands-list">
      <div v-for="(commands, category) in groupedCommands" :key="category">
        <h5 v-if="category" class="pt-2 mb-1 border-top text-uppercase" style="border-color: black !important;">
          {{ category | __t(`command__category__${toSlug(category)}`) }}
        </h5>

        <!-- 'border-bottom': currentActiveId !== command.id && index !== commands.length - 1, -->
        <b-list-group-item
          v-for="command in commands"
          :key="command.id"
          :id="`icon-lock__${command.id}`"
          @click.stop="handleClick($event, command.id, command.isDisabled)"
          :class="{
            'is-active': currentActiveId === command.id,
            'is-disabled': command.isDisabled && !isHidingLock,
          }"
          class="mt-1 pt-2 pb-2 bg-black cursor-pointer border-0"
          variant="transparent"
        >
          <p class="mb-0 text-uppercase font-weight-normal">
            {{ command.name | __t(`command__name__${toSlug(command.name)}`) }}

            <img v-if="command.isDisabled && !isHidingLock" src="@/assets/icons/lock.svg" width="20" height="20" />
          </p>

          <p class="mb-0">
            <small>{{ command.description | __t(`command__description__${toSlug(command.description)}`) }}</small>
          </p>

          <p v-if="!command.isDisabled && command.creditsEstimation" class="mb-0 text-right credits" style="display: none">
            <small>
              <em>({{ command.creditsEstimation }})</em>
            </small>
          </p>
        </b-list-group-item>
      </div>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

export default {
  name: "SpotlightList",
  components: { BListGroup, BListGroupItem },
  props: {
    prefill: {
      type: String,
      default: "",
    },
    commands: {
      type: Array,
      default: () => [],
    },
    isHidingLock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredCommands() {
      const { commands } = this;

      if (!this.prefill) return commands;
      return commands.filter((command) => command.name.toLowerCase().includes(this.prefill.toLowerCase()));
    },
    groupedCommands() {
      const grouped = {};

      this.filteredCommands.forEach((command) => {
        if (!grouped[command.category]) {
          grouped[command.category] = [];
        }
        grouped[command.category].push(command);
      });
      return grouped;
    },
    flatCommands() {
      return this.groupedCommands ? Object.values(this.groupedCommands).flat() : [];
    },
  },
  data: () => ({
    currentIndex: 0,
    currentActiveId: null,
  }),
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      const flatCommands = this.flatCommands;
      const currentIndex = flatCommands.findIndex((command) => command.id === this.currentActiveId);
      let newIndex = currentIndex;

      if (e.key === "ArrowDown") {
        newIndex = currentIndex + 1 < flatCommands.length ? currentIndex + 1 : currentIndex;
      } else if (e.key === "ArrowUp") {
        newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : currentIndex;
      }

      if (newIndex !== currentIndex && flatCommands[newIndex]) {
        this.currentActiveId = flatCommands[newIndex].id;

        // Scroll into view logic
        this.$nextTick(() => {
          const activeElement = this.$el.querySelector(".list-group-item.is-active");

          if (activeElement) {
            activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
          }
        });
      }

      if (e.key === "Enter" && currentIndex !== -1) {
        this.$emit("action", flatCommands[currentIndex].id);
      }
    },
    handleClick(_, id, isDisabled = false) {
      if (isDisabled) {
        this.$emit("open-register");
        return;
      }

      this.currentActiveId = id;
      this.$emit("action", id);
    },

    toSlug,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_settings";

.commands-list {
  height: 550px;
  max-height: 100vh;
  overflow-y: scroll;
}

.commands-list .list-group-item {
  border-radius: 25px !important;
}

.commands-list .list-group-item.is-disabled {
  opacity: 0.6;
}

.commands-list .list-group-item:hover,
.commands-list .list-group-item.is-active {
  background-color: $lavender !important;
  border-radius: 25px !important;

  .credits {
    display: block !important;
  }
}
</style>
