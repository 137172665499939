<template>
  <div :class="themeClass" class="w-100 p-3 rounded-lg shadow-lg">
    <div v-if="!isHidingInput" class="d-flex justify-content-center align-items-center p-2 spotlight-input__wrapper rounded-lg">
      <img :src="require(`@/assets/icons/${icon}.svg`)" width="25" height="25" class="mr-2" />

      <b-form-input
        ref="spotlight-input"
        class="spotlight-input flex-grow-1"
        v-model="query"
        :placeholder="placeholder | __t(`placeholder__${toSlug(placeholder)}`)"
        @keyup.enter="$emit('success')"
      />
    </div>

    <spotlight-list :prefill="query" :commands="commands" :is-hiding-lock="isHidingLock" v-on="$listeners" />
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

import SpotlightList from "./List";

export default {
  name: "Spotlight",
  components: { BFormInput, SpotlightList },
  props: {
    icon: {
      type: String,
      default: "pen",
    },
    placeholder: {
      type: String,
      default: "Describe your next task...",
    },

    commands: {
      type: Array,
      default: () => [],
    },
    themeClass: {
      type: String,
      default: "bg-black",
    },
    isHidingInput: {
      type: Boolean,
      default: false,
    },
    isHidingLock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    query: "",
  }),
  async mounted() {
    const { isMobile, isHidingInput } = this;

    if (!isMobile && !isHidingInput) {
      await this.$nextTick();
      this.$refs["spotlight-input"].focus();
    }
  },
  methods: {
    toSlug,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_settings";

.spotlight-input__wrapper {
  background-color: $dark;
  // border: 1px solid $black-100;
}

.spotlight-input.form-control {
  /* background-color: #986bef; */
  background-color: $dark;
  border: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

.spotlight-input.form-control::placeholder {
  color: #ffffff;
  opacity: 0.5;
}

.spotlight-input:hover,
.spotlight-input:focus,
.spotlight-input:active {
  border: 0 !important;
  box-shadow: none !important;
}

@media screen and (max-width: 768px) {
  .spotlight-input.form-control {
    font-size: 1.2rem;
  }
}
</style>
