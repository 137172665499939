<template>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center pr-4">
    <spotlight
      @action="triggerCreateDocumentFile"
      :commands="DOCUMENTS_OPTIONS_COMMAND"
      icon="blank-page"
      placeholder="Select the template of your new file"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CREATE, UPDATE } from "@/store/actions";

import { DOCUMENTS_TYPES, TEMPLATES, STRUCTURES_OPTIONS, DOCUMENTS_OPTIONS_COMMAND } from "@/constants/documents";
import { TEMPLATES_DATA, BLOCKS_DATA } from "@/constants/documentsTemplates";

import { generateKey } from "@/helpers/utils";

import Spotlight from "@/components/Spotlight/";

export default {
  name: "TaskDocumentWizard",
  components: { Spotlight },
  computed: {
    ...mapGetters("projectsDocuments", ["documentsByParentId"]),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  data: () => ({
    type: null,
    templateId: null,
    structureId: null,

    newName: null,
    newStructureId: null,
    newContent: null,

    keyTiptap: generateKey(),

    isLoading: true,
    isSending: false,

    DOCUMENTS_TYPES,
    TEMPLATES,
    STRUCTURES_OPTIONS,
    DOCUMENTS_OPTIONS_COMMAND,
  }),
  methods: {
    ...mapActions("projectsDocuments", { createDocument: CREATE, updateDocument: UPDATE }),
    ...mapActions("projectsDocumentsBlocks", { createDocumentBlock: CREATE }),
    async triggerCreateDocumentFile(templateId) {
      this.isSending = true;

      const { projectId } = this;
      const { parentId = null } = this.$route.query;

      const type = DOCUMENTS_TYPES.FILE;
      const position = this.documentsByParentId({ parentId }).length;

      const { id: documentId } = await this.createDocument({ projectId, type, parentId, templateId, position });

      let templateContent = "";

      const blocks = TEMPLATES_DATA[templateId];

      if (blocks) {
        for await (const b of blocks) {
          let { value: blockValue, data: blockData = {} } = BLOCKS_DATA[b];

          if (blockValue.includes("BLOCK_ID")) {
            const { id: blockId } = await this.createDocumentBlock({ projectId, documentId, ...blockData });
            blockValue = blockValue.replaceAll("BLOCK_ID", blockId);
          }

          templateContent = `${templateContent} ${blockValue}`;
        }

        templateContent = templateContent.replaceAll("DOCUMENT_ID", documentId);
      }

      await this.updateDocument({ projectId, id: documentId, content: templateContent });

      this.$router.push({ name: "ProjectDocument", params: { documentId } });

      this.isSending = false;
    },
  },
};
</script>
