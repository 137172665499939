import { TEMPLATES, BLOCKS } from "@/constants/documents";

export const BLOCKS_DATA = {
  [BLOCKS.SUBTITLES]: { value: "<block-subtitles data-document-id='DOCUMENT_ID' data-block-id='BLOCK_ID'></block-subtitles>" },
  [BLOCKS.CONTENT]: {
    value: "<block-content data-document-id='DOCUMENT_ID' data-block-id='BLOCK_ID'></block-content>",
    data: { headline: "", content: "", placeholder: "" },
  },
  [BLOCKS.CONTENT_PERSONA]: {
    value: "<block-content data-document-id='DOCUMENT_ID' data-block-id='BLOCK_ID'></block-content>",
    data: { headline: "About You", type: "about", content: "", placeholder: "Tell me more about the persona." },
  },
  [BLOCKS.CONTENT_RULES_WRITING]: {
    value: "<block-content data-document-id='DOCUMENT_ID' data-block-id='BLOCK_ID'></block-content>",
    data: {
      headline: "Writing Rules",
      type: "writing-rules",
      content: "",
      placeholder: "Write down the writing rules or analyze a set of documents.",
    },
  },
  [BLOCKS.CONTENT_EXAMPLES]: {
    value: "<block-content-examples data-document-id='DOCUMENT_ID' data-block-id='BLOCK_ID'></block-content-examples>",
    data: { headline: "Examples", type: "examples" },
  },
};

export const TEMPLATES_DATA = {
  [TEMPLATES.TRANSCRIPTION]: [BLOCKS.SUBTITLES],
  [TEMPLATES.PERSONA]: [BLOCKS.CONTENT_PERSONA, BLOCKS.CONTENT_RULES_WRITING, BLOCKS.CONTENT_EXAMPLES],
  [TEMPLATES.BLOG_POST]: [],
};
